.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
}

.header {
  margin: 25px 0;
  text-align: center;
}

.header img {
  margin-bottom: 10px;
}

.footer {
  width: 80%;
  min-width: 250px;
  max-width: 400px;
  padding-top: 25px;
  margin-top: 10vh;
  border-top: 1px solid #ddd;
}

.footer p {
  margin: 0;
  text-align: center;
  color: #444;
}
