.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.card {
  margin: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

thead {
  font-weight: bold;
}

td {
  text-align: center;
}
